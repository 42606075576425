var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"V5ANfriicT5W06-MreGZP"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN || null
const ENVIRONMENT = process.env.NODE_ENV === 'production' ? 'production' : 'development'

if (SENTRY_DSN) {
  Sentry.init({
    autoSessionTracking: false,
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    initialScope: {
      tags: { container: 'client' },
    },
    normalizeDepth: 10,
  })
}
